import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import { storageHelper } from '../storageHelper'

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
interface ReqInfo {
  url: string
  method: MethodType
  headers: Record<string, string>
  bodyArgs: APIBodyArgCollection
}

type APIQueryArgCollection = ApiTypes.ApiBodyArgCollection
type APIBodyArgCollection = ApiTypes.ApiBodyArgCollection

export class ApiProxy {

  public async sendRequest(
    method: MethodType,
    pathPrefix: string,
    urlArgs: APIQueryArgCollection = {},
    bodyArgs: APIBodyArgCollection | FormData = {}
  ): Promise<any> {
    let path = ''
    if (path.indexOf('http') === 0) {
      path = commonUtils.underlizeKey(path)
    } else {
      if (envHelper.isProd) {
        path = `${envHelper.apiHost}${envHelper.apiBasePath}${commonUtils.underlizeKey(pathPrefix)}`
      } else {
        path = `${envHelper.apiBasePath}${commonUtils.underlizeKey(pathPrefix)}`
      }
    }
    const url = commonUtils.genApiUrl(path, commonUtils.underlize(urlArgs || {}))
    const headers = await this.getAuthHeaders()
    return this.handleSendRequest({
      url, headers, method,
      bodyArgs
    })
  }

  private handleSendRequest(reqInfo: ReqInfo): Promise<any> {
    const body: RequestInit = {
      method: reqInfo.method,
      headers: reqInfo.headers,
    }
    if (reqInfo.method === 'POST') {
      if (reqInfo.bodyArgs instanceof FormData) {
        body.body = reqInfo.bodyArgs
        // NOTE: react 中使用 fetch 上传 formData 数据，不能设置 ContentType 的请求头,否则上传失败
        // PS: https://segmentfault.com/a/1190000010205162
        // body.headers = {
        // 'Content-Type': 'application/x-www-form-urlencoded'
        // 'Content-Type': 'multipart/form-data'
        // }
      } else {
        body.body = JSON.stringify(commonUtils.underlize(reqInfo.bodyArgs || {}))
      }
    }
    const req = fetch(reqInfo.url, body)
    return req.then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw commonUtils.camelize(res)
    }).then((res) => {
      if (res.success) {
        return commonUtils.camelize(res)
      }
      throw commonUtils.camelize(res)
    })
  }

  private async getAuthHeaders(): Promise<{ [k: string]: string }> {
    const data = storageHelper.get(['kuaixieAdmin']).kuaixieAdmin
    if (data && Date.now() < data.expiredAt) {
      return {
        'X-Admin-Token': data.token
      }
    }
    return {}
  }

}
