class EnvHelper {

  get extensionPrefix() {
    return 'kuaixie'
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get productName() {
    return '快写'
  }

  get host() {
    return process.env.REACT_APP_HOST
  }

  get webHost() {
    return process.env.REACT_APP_WEB_HOST
  }

  get apiHost() {
    return this.webHost
  }

  get apiBasePath() {
    return '/kx_admin'
  }

}

export const envHelper = new EnvHelper()
