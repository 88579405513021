
import { get, post } from './common'

export namespace template {
  export function up(p: PostData.TemplateParam) {
    return post('/gpt/module/up', {}, p)
  }
  export function sort(moduleIdList: number[]) {
    return post('/gpt/module/order', {}, { moduleIdList })
  }
  export function categoryList() {
    return get<{ data: ServerData.Category.One[] }>('/gpt/module/category/list')
  }

  export function templateList() {
    return get<{ data: ServerData.Template.One[] }>('/gpt/module/list')
  }

  export function active(p: { moduleId: number, active: boolean }) {
    return post('/gpt/module/active', {}, p)
  }
  export function one(moduleId: number) {
    return get<{
      data: ServerData.Template.DetailOne
    }>('/gpt/module/one', { moduleId })
  }
  export function del(p: { moduleId: number }) {
    return post('/gpt/module/del', {}, p)
  }
}

