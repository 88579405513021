export type PathKey = keyof typeof PAGE_LINKS
export type Path = typeof PAGE_LINKS[PathKey]

export const PAGE_LINKS = {
  login: '/login',
  templateManage: '/template',
  feedback: '/feedback'
}



export const USER_MAP = [
  {
    account: 'bsx',
    password: 'RCR52NWbOFKLMqC0',
    userName: '鲍爽新'
  },
  {
    account: 'wangpeng',
    password: 'xmNf3y!51fIv$q3v',
    userName: '王鹏'
  },
  {
    account: 'fuchunming',
    password: 'vDTHJYnlqLRbS6Sa',
    userName: '付纯明'
  }
]