import { get, post } from './common'
// type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace user {
  export function me() {
    return get<{ account: string }>('/user/me')
  }
  export function login(param: { account: string, password: string }) {
    return post<{ account: string, token: string }>('/login', {}, param)
  }

}

