import { action, makeObservable, observable } from 'mobx'
import { createContext } from 'react'
import { api } from '@/helpers/api'
import { storageHelper } from '@/helpers/storageHelper'


interface UserData {
  account: string
}

const DAY = 1000 * 3600 * 24
class UserService {
  @observable userData: UserData | null = null
  @observable fetching: boolean = false

  constructor() {
    makeObservable(this)
  }

  @action updateUserData(userData: UserData | null) {
    this.userData = userData
  }

  public async login(data: { account: string, password: string }) {
    const res = await api.user.login(data)
    this.updateUserData(res)
    if (res.token) {
      storageHelper.set({ kuaixieAdmin: { token: res.token, expiredAt: Date.now() + DAY } })
    }

  }

  public async init() {
    this.fetching = true
    try {
      const userData = await api.user.me()
      this.updateUserData(userData)
    } catch (error) {
      this.updateUserData(null)
    }
    this.fetching = false
  }


  public async logout() {
    // this.updateUserData(null)
  }

}

export const userService = new UserService()
export const userContext = createContext(userService)

