import { MessageInstance } from 'antd/es/message/interface'
import { makeObservable, observable } from 'mobx'

class UiController {
  @observable message!: MessageInstance
  @observable test = false

  public init() {
    makeObservable(this)
  }

}

export const uiController = new UiController()
