import { get, post } from './common'

export namespace feedback {
  export function list(p: { page: number, pageSize: number }) {
    return get<{
      data: ServerData.Feedback.One[]
      total: number
    }>('/gpt/common/list', p)
  }
  export function del(feedId: number) {
    return post('/gpt/common/del', {}, { feedId })
  }
}