import classNames from "classnames";
import { observer } from "mobx-react";
import { GiftOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { FC, createContext, useContext, useEffect, useState } from "react";
import styles from "./Layout.module.less";
import { Layout as AntdLayout, Menu, MenuProps, theme, Dropdown, message, } from "antd";
import { userContext, } from '@/services/userService';
import { useLocalNavigate } from '@/Router';
import { useLocation } from 'react-router-dom';
import { PAGE_LINKS } from '@/helpers/commonConsts';

const { Header, Content, Sider } = AntdLayout;

interface LayoutProps {
  children: React.ReactNode;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: SideKeys,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return { key, icon, children, label, } as MenuItem;
}

type SideKeys = keyof typeof PAGE_LINKS
// 'templateManage' | 'feedback'

const Layout: FC<LayoutProps> = observer((props: LayoutProps) => {
  const location = useLocation()
  const navigate = useLocalNavigate()
  const { token: { colorBgContainer }, } = theme.useToken();
  const userService = useContext(userContext)
  const [sideKey, setSideKey] = useState<SideKeys>('templateManage')



  useEffect(() => {
    const path = Object.keys(PAGE_LINKS).find((path) => PAGE_LINKS[path as keyof typeof PAGE_LINKS] === location.pathname) || 'templateManage'
    setSideKey(path as SideKeys)
  }, [location.pathname])

  const sideBarItems: MenuItem[] = [
    getItem("模版列表", "templateManage", <GiftOutlined />),
    getItem("客户反馈", "feedback", <GiftOutlined />),
  ];

  const userItems: MenuItem[] = [
    {
      label: (<div>注销</div>),
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        message.warning('这个登出还没搞')
        // userService.logout()
      }
    },
  ]


  return (
    <AntdLayout className={classNames(styles.layout)} >
      <Header
        className={classNames(styles.header)}
        style={{
          display: "flex",
          alignItems: "center",
          background: colorBgContainer,
          borderBottom: "1px solid #e8e8e8",
        }}
      >
        <div className={classNames(styles.layoutTitle)}>快写后台管理系统</div>
        <div className="user-box" style={{ cursor: 'pointer' }}>
          <Dropdown menu={{ items: userItems }} placement="bottomLeft">
            <span>{userService.userData ? userService.userData.account : '-'}</span>
          </Dropdown>
        </div>
      </Header>
      <Content style={{ height: "100%" }}>
        <AntdLayout
          style={{
            background: colorBgContainer,
            height: "100%",
          }} >
          {sideKey !== 'login' &&
            <Sider
              className={classNames(styles.sider)}
              style={{ background: colorBgContainer, height: "100%" }}
              width={200} >
              <Menu
                mode="inline"
                className={classNames(styles.menu)}
                onClick={(res) => {
                  setSideKey(res.key as SideKeys)
                  navigate(res.key as any)
                }}
                selectedKeys={[sideKey]}
                style={{ height: "100%" }}
                items={sideBarItems}
              />
            </Sider>
          }
          <Content style={{ padding: "20px", minHeight: 280 }}>
            {props.children}
          </Content>
        </AntdLayout>
      </Content>
    </AntdLayout>
  );
});

export default Layout;
