import { ConfigProvider, message } from 'antd'
// import { useLayoutEffect } from 'react'
import {
  BrowserRouter,
  // useLocation,
  // useNavigate,
  // useParams,
  // useSearchParams,
} from 'react-router-dom'
import './App.less'
import { Router, routes, } from './Router'
import CommonDialogLayout from './components/CommonDialogLayout/CommonDialogLayout'
import { uiController } from './controllers/uiController'
// import { commonUtils } from './helpers/commonUtils'
// import { routerService } from './services/routerService/routerService'
import { userService } from './services/userService'
import Layout from './components/Layout/Layout'

const RouterLayout = () => {
  // 只要使用了 useNavigate ，url 变化都会导致此组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const params: any = useParams()
  // routerService.navigate = useNavigate()
  // const location = useLocation()
  // routerService.location = location
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [_, setSearchParams] = useSearchParams()
  // routerService.setURLSearchParams = setSearchParams
  // useLayoutEffect(() => {
  // routerService.params = {
  //   ...params,
  //   ...commonUtils.getUrlParams(),
  // }
  // })
  return null
}

const TOKEN = {
  token: { colorPrimary: '#3872E0', colorText: '#212B36', }
}

export default function App() {
  const [messageApi, contextHolder] = message.useMessage()
  uiController.message = messageApi
  return (
    <ConfigProvider autoInsertSpaceInButton={false} theme={TOKEN} >
      <BrowserRouter>
        <CommonDialogLayout />
        {contextHolder}
        {/* <RouterLayout /> */}
        <Layout>
          <Router routes={routes} />
        </Layout>
      </BrowserRouter>
    </ConfigProvider>
  )
}

uiController.init()
userService.init()
